import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import buyTokensMoney from '../../assets/buyTokensMoney.png';
import './buytokens.css';
import { TitleText } from '../../components';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import CustomButton from "../../components/customButton/CustomButton";

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { arbitrum, mainnet, goerli, sepolia, polygon } from 'wagmi/chains'

/*
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { arbitrum, mainnet, goerli, polygon } from 'wagmi/chains'
*/

import web3Provider from '../../components/web3Contracts/web3Provider';
import USDTContract from '../../components/web3Contracts/USDT.js';
import tokenContract from '../../components/web3Contracts/token';
import vendorContract from '../../components/web3Contracts/vendor.js';// doesn't show website on phones

import SubmitButton from "./SubmitButton";

import ProgressBar from "../../components/progressBar/ProgressBar";
import LoadingBar from "../../components/loadingBar/LoadingBar";

function CommaFormatted(amount) {
	var delimiter = ","; // replace comma if desired
	var a = CurrencyFormatted(amount).split('.',2)
	var d = a[1];
	var i = parseInt(a[0]);
	if(isNaN(i)) { return ''; }
	var minus = '';
	if(i < 0) { minus = '-'; }
	i = Math.abs(i);
	var n = new String(i);
	var a = [];
	while(n.length > 3) {
		var nn = n.substring(n.length-3);
		a.unshift(nn);
		n = n.substring(0,n.length-3);
	}
	if(n.length > 0) { a.unshift(n); }
	n = a.join(delimiter);
	if(d.length < 1) { amount = n; }
	else { amount = n + '.' + d; }
	amount = minus + amount;
	return amount;
}

function CurrencyFormatted(amount) {
	var i = parseFloat(amount);
	if(isNaN(i)) { i = 0.00; }
	var minus = '';
	if(i < 0) { minus = '-'; }
	i = Math.abs(i);
	i = parseInt((i + .005) * 100);
	i = i / 100;
	var s = new String(i);
	if(s.indexOf('.') < 0) { s += '.00'; }
	if(s.indexOf('.') === (s.length - 2)) { s += '0'; }
	s = minus + s;
	return s;
}

function ETHCommaFormatted(amount) {
	var delimiter = ","; // replace comma if desired
	var a = ETHFormatted(amount).split('.',3)
	var d = a[1];
	var i = parseInt(a[0]);
	if(isNaN(i)) { return ''; }
	var minus = '';
	if(i < 0) { minus = '-'; }
	i = Math.abs(i);
	var n = new String(i);
	var a = [];
	while(n.length > 3) {
		var nn = n.substring(n.length-3);
		a.unshift(nn);
		n = n.substring(0,n.length-3);
	}
	if(n.length > 0) { a.unshift(n); }
	n = a.join(delimiter);
	if(d.length < 1) { amount = n; }
	else { amount = n + '.' + d; }
	amount = minus + amount;
	return amount;
}

function ETHFormatted(amount) {
	var i = parseFloat(amount);
	if(isNaN(i)) { i = 0.000000; }
	var minus = '';
	if(i < 0) { minus = '-'; }
	i = Math.abs(i);
	i = parseInt((i + .0000005) * 1000000);
	i = i / 1000000;
	var s = new String(i);
	if(s.indexOf('.') < 0) { s += '.000000'; }
  while(s.indexOf('.') !== (s.length - 7)) {s += '0';}
	s = minus + s;
	return s;
}

const { ethers } = require("ethers");

const chains = [goerli]
const projectId = 'd3b220b15e40150c8335431b7e921006'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains}),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains[1])
/*
const chains = [goerli]
const projectId = 'd3b220b15e40150c8335431b7e921006'
const { provider } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ 
    projectId, 
    version: 1, 
    chains }),
  provider
})
const ethereumClient = new EthereumClient(wagmiClient, chains)
*/
 
  class Buytokens extends Component {

    state = {
      myAddress: '',
      myETHBalance: '',
      myUSDTBalance: '',
      myCACATOBalance: '',
      walletConnectConnected: false,
      buyMessage: '',
      vendorTokenBalance:'',
      vendorMinPurchase: '',
      vendorMaxPurchase: '',
      vendorSalePrice: '',
      ETHLatestPrice: '',      
      tokenAddress: '',
      valueToPay: '',      
      paymentCurrency: '',
      valueToBuy: '',
      status: "idle",
      errorMessage: '',
      tokensPurchased: '',
      trigger: false,
      formValidated: false,
      tableStates0: [],
      tableStates1: [null, null, null, null, null, null, null, null],
      tableChanges: [],
    }
    
    async formValidate(name, event) {
      if (name ==="valueToPay"){
        await this.setState({valueToPay:event.target.value});
        await this.setValueToBuy(this.state.paymentCurrency, event.target.value)
      } else if (name ==="valueToBuy"){
        await this.setState({valueToBuy:event.target.value});
        await this.setValueToPay(this.state.paymentCurrency, event.target.value)
      } else if (name ==="paymentCurrency"){
        await this.setState({paymentCurrency: event.target.value});
        await this.setPayAndBuyValues(event.target.value);
      }

      if (this.state.valueToPay !=='' && this.state.valueToBuy !=='' && this.state.paymentCurrency !==''){
        await this.setState({formValidated: true});
        console.log("55", this.state.formValidated);
      } else {
        await this.setState({formValidated: false});
        console.log(this.state.formValidated);
      }
    }








    async componentDidMount(){
      console.log("componentDidMount");
      if (this.walletConnectConnected) {
        this.isConnected();
      }
      
    }

    handleEventCB = (isConnected) => {
      this.setState({walletConnectConnected: isConnected})
      this.isConnected();
    }

    wait (ms) {
      var start = new Date().getTime();
      var end = start;
      while(end < start + ms) {
        end = new Date().getTime();
     }
    }

     handleEventSB = async(statusSB, errorMsg, tokensBought) => {      
      for(var i=0; i < 8; i++){
        if (this.state.tableChanges[i] != false) {
          this.state.tableChanges[i] = false;
          this.forceUpdate();
        };        
      }
      if (statusSB === "buyWithUSDTSale" || statusSB === "buyWithETHSale") {
        //this.wait(2000);
        await this.checkForChanges(statusSB);//to check if data have changed on the blockchain after the sale
        this.setState({tokensPurchased: tokensBought, status: "success"})
        await this.isConnected();        
        } else if (statusSB === "loading") {
        this.setState({status: "loading"})
      } else if (statusSB === "idle") {
        this.setState({status: "idle"})//set errorMsg needed
      } else if (statusSB === "error"){
        const endIndex =  errorMsg.search('Request Arguments:')
        this.setState({errorMessage: "Error: "+ errorMsg.substring(0, endIndex)})

        
        /*
        console.log("123" + errorMsg)
        if (errorMsg.indexOf("User rejected the request") != -1) {
          this.setState({errorMessage: "Error: "+ errorMsg})          
        } else if (errorMsg.indexOf("execution reverted:") != -1) {
          const startIndex =  errorMsg.search('execution reverted:')
          const endIndex =  errorMsg.search('", method="estimateGas",')
          if (endIndex  >= 0) {
            this.setState({errorMessage: "Error: "+ errorMsg.substring(startIndex+20, endIndex)})
        }} else {
          const startIndex =  errorMsg.search('message":"err:')
          const endIndex =  errorMsg.search(': address')
          if (endIndex  >= 0) {
            this.setState({errorMessage: "Error: "+ errorMsg.substring(startIndex+15, endIndex)})
        }};*/
        this.setState({status: "error"}); 
      }
    }

    checkForChanges = async(statusSB) => {
      if (statusSB === "buyWithUSDTSale") {
        let i1, i2, i3, i4;
        var checkStart = new Date().getTime();
        var checkEnd = checkStart;
        let flag = true;
        do {
          checkEnd = new Date().getTime();
          i1 = await vendorContract.methods.getTokenBalance().call();
          await (i1 = CommaFormatted(web3Provider.utils.fromWei(i1, 'ether')));
          i2 = await web3Provider.eth.getBalance(this.state.myAddress);
          await (i2 = ETHCommaFormatted(web3Provider.utils.fromWei(i2, 'ether'))); //    .substring(0, 12)
          i3 = await USDTContract.methods.balanceOf(this.state.myAddress).call();
          await (i3 = CommaFormatted(web3Provider.utils.fromWei(i3, 'mwei')));
          i4 = await tokenContract.methods.balanceOf(this.state.myAddress).call();
          await (i4 = CommaFormatted(web3Provider.utils.fromWei(i4, 'ether')));
          if (checkEnd < checkStart + 10000) {
            if ( (this.state.tableStates0[0] === i1) || (this.state.tableStates0[5] === i2) || (this.state.tableStates0[6] === i3) || (this.state.tableStates0[7] === i4)){
              flag = true;
            } else {flag = false;}
          } else {flag = false;}
        } while (flag);
      } else if (statusSB === "buyWithETHSale") {
        let i1, i2, i3;
        var checkStart = new Date().getTime();
        var checkEnd = checkStart;
        let flag = true;
        do {
          checkEnd = new Date().getTime();
          i1 = await vendorContract.methods.getTokenBalance().call();
          await (i1 = CommaFormatted(web3Provider.utils.fromWei(i1, 'ether')));
          i2 = await web3Provider.eth.getBalance(this.state.myAddress);
          await (i2 = ETHCommaFormatted(web3Provider.utils.fromWei(i2, 'ether'))); //    .substring(0, 12)
          i3 = await tokenContract.methods.balanceOf(this.state.myAddress).call();
          await (i3 = CommaFormatted(web3Provider.utils.fromWei(i3, 'ether')));
          if (checkEnd < checkStart + 10000) {
            if ( (this.state.tableStates0[0] === i1) || (this.state.tableStates0[5] === i2) || (this.state.tableStates0[7] === i3)){
              flag = true;
            } else {flag = false;}            
          } else {flag = false;}
        } while (flag);
      }
    }

    isConnected = async()=> {
      const accounts = await window.ethereum.request({method: 'eth_accounts'});       
      if (accounts.length) {
         let myAddress = accounts[0];
         let vendorTokenBalance = await vendorContract.methods.getTokenBalance().call();
         await (vendorTokenBalance = CommaFormatted(web3Provider.utils.fromWei(vendorTokenBalance, 'ether')));
         let vendorMinPurchase = await vendorContract.methods.minBuyAmount().call();
         vendorMinPurchase = CommaFormatted(web3Provider.utils.fromWei(vendorMinPurchase, 'ether'));
         let vendorMaxPurchase = await vendorContract.methods.maxBuyAmount().call();
         vendorMaxPurchase = CommaFormatted(web3Provider.utils.fromWei(vendorMaxPurchase, 'ether'));
         let vendorSalePrice = await vendorContract.methods.salePrice().call();
         vendorSalePrice = web3Provider.utils.fromWei(vendorSalePrice, 'mwei');
         let ETHLatestPrice = await vendorContract.methods.getETHLatestPrice().call();
         await this.setState({vendorTokenBalance, vendorMinPurchase, vendorMaxPurchase, vendorSalePrice, ETHLatestPrice});

         let myETHBalance = await web3Provider.eth.getBalance(accounts[0]);
         await (myETHBalance = ETHCommaFormatted(web3Provider.utils.fromWei(myETHBalance, 'ether'))); //    .substring(0, 12)
         let myUSDTBalance = await USDTContract.methods.balanceOf(accounts[0]).call();
         await (myUSDTBalance = CommaFormatted(web3Provider.utils.fromWei(myUSDTBalance, 'mwei')));
         let myCACATOBalance = await tokenContract.methods.balanceOf(accounts[0]).call();
         await (myCACATOBalance = CommaFormatted(web3Provider.utils.fromWei(myCACATOBalance, 'ether')));
         await this.setState({myAddress, myETHBalance, myUSDTBalance, myCACATOBalance});
         this.inspectValues();
         } else {
         console.log("Wallet is not connected.");
      }
    }


    inspectValues = async() => {
      if (this.state.tableStates0[0] == null) {
        this.setState({tableStates0: [this.state.vendorTokenBalance, this.state.vendorMinPurchase, this.state.vendorMaxPurchase, this.state.vendorSalePrice,
        this.state.myAddress, this.state.myETHBalance, this.state.myUSDTBalance, this.state.myCACATOBalance]}) 
        } else {
        this.state.tableStates1[0] = this.state.vendorTokenBalance;
        this.state.tableStates1[1] = this.state.vendorMinPurchase;
        this.state.tableStates1[2] = this.state.vendorMaxPurchase;
        this.state.tableStates1[3] = this.state.vendorSalePrice;
        this.state.tableStates1[4] = this.state.myAddress;
        this.state.tableStates1[5] = this.state.myETHBalance;
        this.state.tableStates1[6] = this.state.myUSDTBalance;
        this.state.tableStates1[7] = this.state.myCACATOBalance;
        this.forceUpdate();
      }

      for(var i=0; i < 8; i++){
          if((this.state.tableStates0[i] != this.state.tableStates1[i]) && (this.state.tableStates1[i] != null)) {
            this.state.tableChanges[i] = true;
            this.state.tableStates0[i] = this.state.tableStates1[i];
          } else {
            this.state.tableChanges[i] = false;
          }
          this.state.tableStates1[i] = null;
          this.forceUpdate();
          }
    }



    setValueToPay = (currency, toBuy) => {
      if (toBuy !== '' && currency === '10') {
        this.setState({valueToPay: (1.01*(toBuy*this.state.vendorSalePrice/web3Provider.utils.fromWei(this.state.ETHLatestPrice, 'ether'))).toFixed(10)});
      } else if (toBuy !== '' && currency === '20') {
        this.setState({valueToPay: (toBuy*this.state.vendorSalePrice).toFixed(2)})
      } else if (toBuy === '') {
        this.setState({valueToPay: ''})
      }
    }

    setValueToBuy = (currency, toPay) => {
      if (toPay !== '' && currency === '10') {
        this.setState({valueToBuy: (0.99*(toPay*web3Provider.utils.fromWei(this.state.ETHLatestPrice, 'ether')/this.state.vendorSalePrice)).toFixed(2)})
      } else if (toPay !== '' && currency === '20') {
        this.setState({valueToBuy: (toPay/this.state.vendorSalePrice).toFixed(2)})      
      } else if (toPay === '') {
        this.setState({valueToBuy: ''})
      }
    }
    setPayAndBuyValues = (currency) => {
      if (this.state.valueToPay === '') {
        this.setValueToPay(currency, this.state.valueToBuy);
      } else {
        this.setValueToBuy(currency, this.state.valueToPay);
      }
    }




  render() {
    return (

    <div className="buytokens section__margin" id="buytokens">
      <div className="title">
        <TitleText title={<>Buy CACATO tokens</>}/>
      </div>
      <div className="dataAndPicture">
        <div className="dataTable">
        <div className="amountToPayTitle">CACATO Sale is Live!
        </div>

        <div className={this.state.walletConnectConnected=== true ? "progressBarContainer" : "progressBarContainer2"}>
          <ProgressBar value={29}/>
        </div>

        <table className={this.state.walletConnectConnected=== true ? "tokenContractData" : "tokenContractData2"}>
            Token contract:
            <tr>
              <td className="rowName">Tokens for sale</td>
              <td className={this.state.tableChanges[0]=== true ? "rowValue1" : "rowValue"}><div >{this.state.vendorTokenBalance} CACATO </div></td>
            </tr>
            <tr>
            <td className="rowName">Minimum purchase </td>
            <td className={this.state.tableChanges[1]=== true ? "rowValue1" : "rowValue"}>{this.state.vendorMinPurchase} CACATO</td>
            </tr>
            <tr>
            <td className="rowName" >Maximum purchase</td>
            <td className={this.state.tableChanges[2]=== true ? "rowValue1" : "rowValue"}>{this.state.vendorMaxPurchase} CACATO</td>
            </tr>
            <tr>
            <td className="rowName" >Price</td>
              <td className={this.state.tableChanges[3]=== true ? "rowValue1" : "rowValue"}>1 CACATO = {this.state.vendorSalePrice} USDT</td>
            </tr>
          </table>
          <table className={this.state.walletConnectConnected=== true ? "connectedWalletData" : "connectedWalletData2"}>
            Connected wallet:
            <tr>
            <td className="rowName" >Wallet address</td>
            <td className={this.state.tableChanges[4]=== true ? "rowValue1" : "rowValue"}><a href={"https://etherscan.io/address/" + this.state.myAddress} target="_blank">{this.state.myAddress.substring(0, 4) + "..." + this.state.myAddress.substring(38, 42)}
             </a></td>
            </tr>
            <tr>
            <td className="rowName" >ETH balance </td>
              <td className={this.state.tableChanges[5]=== true ? "rowValue1" : "rowValue"}>{this.state.myETHBalance} ETH</td>
            </tr>
            <tr>
            <td className="rowName" >USDT balance</td>
            <td className={this.state.tableChanges[6]=== true ? "rowValue1" : "rowValue"}>{this.state.myUSDTBalance} USDT</td>
            </tr>
            <tr>
            <td className="rowName" >CACATO balance</td>
            <td className={this.state.tableChanges[7]=== true ? "rowValue1" : "rowValue"}>{this.state.myCACATOBalance} CACATO</td>
            </tr>
          </table>
        </div>
        <div className="buyTokensImage">
          <img src={buyTokensMoney} alt="buyTokensMoney"/>
        </div>
      </div>
           <div className={this.state.walletConnectConnected=== true ? "connectWalletContainer2" : "connectWalletContainer"} >
          <WagmiConfig config={wagmiConfig}>
            <CustomButton handleEvent={this.handleEventCB}/>
            
          </WagmiConfig>
          
        </div> 
      
      <div className={this.state.walletConnectConnected=== true ? "makeOrderContainer" : "makeOrderContainer2"}>
        
        <form onSubmit= {this.onSubmit}>
          <div className="buyCoins">
            <div className="tokenToPayContainer">
              <div className="textFields">
                           <TextField
                                  sx={{
                                    width: 200, height: 56,
                                    "& .MuiOutlinedInput-root": {
                                      "& > fieldset": { borderColor: "white" },
                                    },
                                    "& .MuiOutlinedInput-root:hover": {
                                      "& > fieldset": { borderColor: "gold" },
                                    },
                                    "& .MuiOutlinedInput-root.Mui-focused": {
                                      "& > fieldset": { borderColor: "gold" },
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                      color: "gold" ,
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: "white" ,
                                    },
                                  }}
                                  id="outlined-input"
                                  label="Amount to Pay"
                                  inputProps={{ style: { color: "white"} }}
                                  InputLabelProps={{style: {fontSize: 16}}}
                                  value={this.state.valueToPay}
                                  required
                                  onChange= {event => (this.formValidate("valueToPay", event))}
                                  autoComplete="off"
                                  > 
                           </TextField>
              </div>
              <div className="textFields">
                      <FormControl sx= {{ width: 120, height: 56,
                                      "& .MuiOutlinedInput-root": {
                                        "& > fieldset": { borderColor: "white" },
                                        color: "white",                                        
                                      },
                                      "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": { borderColor: "gold" },
                                      },
                                      "& .MuiOutlinedInput-root.Mui-focused": {
                                        "& > fieldset": { borderColor: "gold" },
                                      },
                                      "& .MuiInputLabel-root.Mui-focused": {
                                        color: "gold" ,
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "white" ,
                                      },
                                      "& .MuiSelect-icon": {
                                        color: "white" ,
                                      },
                                 }}>
                            <InputLabel id="demo-select-small">Currency</InputLabel>
                            <Select
                              labelId="demo-select-small"
                              id="demo-select-small"
                              value={this.state.paymentCurrency}
                              required
                              onChange={event => (this.formValidate("paymentCurrency", event))}
                              input={<OutlinedInput label="Currency" />}
                              >
                              <MenuItem value={'10'} >ETH</MenuItem>
                              <MenuItem value={'20'}>USDT</MenuItem>
                            </Select>
                        </FormControl>
              </div>
              <div className="textFields">
                =
              </div>
            </div>
            <div className="tokenToBuyContainer">
              <div className="textFields">
                <TextField
                          sx={{
                            width: 200, height: 56,
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": { borderColor: "white" },
                            },
                            "& .MuiOutlinedInput-root:hover": {
                              "& > fieldset": { borderColor: "gold" },
                            },
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": { borderColor: "gold" },
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: "gold" ,
                           },
                           "& .MuiInputLabel-root": {
                              color: "white" ,
                           },
                          }}
                          id="outlined-input"
                          label="Amount to Buy"
                          inputProps={{ style: { color: "white" } }}
                          required
                          value={this.state.valueToBuy}
                          onChange= {event => (this.formValidate("valueToBuy", event))}
                          autoComplete="off"
                  >
                </TextField>
              </div>
              <div className="textField2">
                <TextField
                          sx={{
                            width: 120, height: 56,
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": { borderColor: "white" },
                            },
                            "& .MuiOutlinedInput-root:hover": {
                              "& > fieldset": { borderColor: "gold" },
                            },
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": { borderColor: "gold" },
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                               color: "gold" ,
                            },
                            "& .MuiInputLabel-root": {
                               color: "white" ,
                            },
                          }}

                          id="outlined-read-only-input"
                          label="Token to buy"
                          defaultValue="CACATO"
                          inputProps={{
                            readOnly: true,
                            style: { color: "white" }
                          }}
                    > 
                </TextField>
              </div>
            </div>  
          </div>
        
          <div className="formBuyButton">
              <button className={this.state.formValidated=== true ? "hideFormButton" : "showFormButton"} type="submit">Buy Tokens</button>
          </div>
        </form>
        <div className="formBuyButton">
        <WagmiConfig config={wagmiConfig}>
          <SubmitButton handleEvent={this.handleEventSB} valueToPay = {this.state.valueToPay} paymentCurrency = {(this.state.paymentCurrency)} valueToBuy = {(this.state.valueToBuy)} formValidated = {this.state.formValidated}/>
        </WagmiConfig>
        </div>
        <div className="messageContainer">
            {this.state.status == "error" ? (
              <div class="errorMessage">
                <div >{this.state.errorMessage}</div>
              </div>)
              : null}


            {this.state.status == "loading" ? (
              <div class="loadingContainer">
                <div>
                  <div class="loadingText"> Waiting For Confirmation</div>
                  <div class="loadingText">(this can take up to 30 seconds)</div>
                </div>
                <LoadingBar/>
              </div>)
              : null}

              {this.state.status == "success" ? (
              <div class="successfully"> {"You successfully bought " + this.state.tokensPurchased + " CACATO!"}</div>)
              : null}


            </div>
      </div>
      <div className="infoContainer">
                      <h1>How to buy? | What currency to use? | New to crypto?</h1>
                    
                      <p>MetaMask wallet is required to make a payment. ETH and USDT coins can be used for purchasing CACATO token. 
                        ETH/USDT could be purchased on any cryptocurrency exchange like Coinbase, Robinhood, eth, and transferred to 
                        your MetaMask address. 
                        After purchasing CACATO token it should be added to your MetaMask wallet: login to your account, 
                        go to Assets-Import tokens. Paste token address and click "Add custom token". Your CACATO will 
                        appear in your wallet. 
                      </p>
      </div>
    </div>
  )
}
  }
export default Buytokens;








/*















<form onSubmit= {this.onSubmit}>



    onSubmit = async event => {
      event.preventDefault();
      event.stopPropagation();
      let trigger = true;
      this.setState({trigger});
      try{
        this.setState({statusError: false, success: false, statusLoading: true});
        if (this.state.paymentCurrency==='10') {

        } else {
          alert("22");

          
        let accounts = await web3Provider.eth.getAccounts();
        alert("hi");
        await USDTContract.methods.approve(vendorContract.options.address, web3Provider.utils.toWei(this.state.value, 'mwei')).send({
           from: accounts[0]
        });
        await vendorContract.methods.buyWithUSDT(web3Provider.utils.toWei(this.state.valueToBuy, 'mwei')).call({
          from: accounts[0]
        });
        await vendorContract.methods.buyWithUSDT(web3Provider.utils.toWei(this.state.valueToBuy, 'mwei')).send({
          from: accounts[0]
        }).on("receipt", (receipt) => {
          this.setState({tokensPurchased: web3Provider.utils.fromWei(receipt.events.BuyWithUSDT.returnValues.tokensAmount, 'ether')});
        }).on("error", (error, receipt) => {
          //this.setState({errorMessage: "ERROR "+ error.message, statusLoading: false, success: false, statusError: true})
        });
        //this.setState({statusLoading: false, statusError: false, success: true});
      }
      } catch(error) {
        if (error.message.indexOf("User denied") != -1) {
          //this.setState({errorMessage: "ERROR: "+ error.message, statusLoading: false, success: false, statusError: true})
        } else {
          const startIndex =  error.message.search('execution reverted:')
          const endIndex =  error.message.search('", method="estimateGas",')
          if (endIndex  >= 0) {
            //this.setState({errorMessage: "ERROR: "+ error.message.substring(startIndex, endIndex), statusLoading: false, success: false, statusError: true})
        }};
      }};







*/

















































/*
<div className="connectWalletContainer">
        <div className="connectWallet">
          <button type="button">Connect Wallet</button>
        </div>
      </div>





className={this.walletConnectConnected ? "connectedWallet2" : "connectedWallet"}






{{ m: 1, minWidth: 120, maxWidth: 300  }} 

handleChange = (event) => {
  this.setState({tokenNumber: event.target.value})};  



<motion.div
        className="parentContainer"
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
      >
        <TitleText 
          title={<>Buy CACATO tokens</>}
          
        />
        <div className="header-image">
      <img src={buyTokensMoney} alt="buyTokensMoney"/>
      </div>
      </motion.div>
    </div>*/







