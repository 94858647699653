import React, { useState }  from 'react';
import './games.css';
import { motion } from 'framer-motion';
import { exploreWorlds } from '../../constants/index';
import { staggerContainer } from '../../utils/motion';
import { ExploreCard, TitleText } from '../../components';



  

const Games = () => {
  const [active, setActive] = useState('world-2');

  return (
    <div className="gpt3__games section__margin " id="games">
      <motion.div
        className="parentContainer"
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
      >
        <div className="titleGames">
          <TitleText title={<>Games to explore</>}/>
        </div>
        <div className="childImage">
          {exploreWorlds.map((world, index) => (
            <ExploreCard
              key={world.id}
              {...world}
              index={index}
              active={active}
              handleClick={setActive}
            />
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Games