import { motion } from 'framer-motion';
import './loadingBar.css';

function LoadingBar() {

    const loadingWrapStyle = {
        indeterminate: {
          x: ["-45%", "45%"],
          transition: {
            //ease: [0.35, 0, 0.2, 1],
            repeat: Infinity,
            repeatType: "reverse",
            duration: 1.5
          }
        }
      };

    const loadingBarStyle = {
        indeterminate: {
          width: ["10%", "50%", "10%"],
          transition: {
           // ease: [0.35, 0, 0.2, 1],
            repeat: Infinity,
            repeatType: "reverse",
            duration: 1.5
          }
        }
      };
      return(

        <div className="loadingWrap">
            <motion.div
                className="loadingBarWrap"
                animate="indeterminate"
                variants={loadingWrapStyle}
            >
                <motion.div
                className="loadingBar"
                animate="indeterminate"
                variants={loadingBarStyle}
                />
            </motion.div>
        </div>
  );

};
    
export default LoadingBar;