import React, { Component } from 'react';

import { Navbar } from './components';
import { AboutCACATO, Buytokens, Footer, Games, Header, Reviews } from './containers';
import './App.css';

class App extends Component  {
  
  render() {
    
    return (
  <div className="App">
    <div className="gradient__bg1" >
      <Navbar/>      
      <Header />
    </div>
    <div className="gradient__bg2">
    <AboutCACATO />
    <Games />
    </div>
    <div className="gradient__bg3">
    <Buytokens />
    <Reviews />
    <Footer />
    </div>
  </div>
    );
  }
}

export default App;


