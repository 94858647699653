import React, { Component } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logoWhite.svg';
import './navbar.css';
import tokenContract from '../web3Contracts/token.js';
import web3Provider from '../web3Contracts/web3Provider';
import Web3 from 'web3';
import CustomButton from "../customButton/CustomButton";
/////
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { arbitrum, mainnet, goerli, sepolia, polygon } from 'wagmi/chains'

const chains = [goerli]
const projectId = 'd3b220b15e40150c8335431b7e921006'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains}),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains[1])
/////
/*
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal, Web3Button, useWeb3ModalTheme   } from '@web3modal/react'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'

const chains = [mainnet]
const projectId = 'd3b220b15e40150c8335431b7e921006'
const { provider } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ 
    projectId, 
    version: 1, 
    chains }),
  provider
})
const ethereumClient = new EthereumClient(wagmiClient, chains)
*/
  class Navbar extends Component {
      state = {
      toggleMenu: false,
      myAddress: '',
      myETHBalance: '',
      myCACATOBalance: '',
      walletConnectConnected: false,
    }
  
    Menu = () => (
      <>
      <p><a href="#home" onClick={() => this.setState({toggleMenu: false})}>Home</a></p>
      <p><a href="#aboutCACATO" onClick={() => this.setState({toggleMenu: false})}>About</a></p>
      <p><a href="#games" onClick={() => this.setState({toggleMenu: false})}>Games</a></p>
      <p><a href="#buytokens" onClick={() => this.setState({toggleMenu: false})}>Buy Tokens</a></p>
      <p><a href="#reviews" onClick={() => this.setState({toggleMenu: false})}>Reviews</a></p>
      </>
    )
    
    connectWalletHandler = async()=> {
      let web3
      let accounts
      let myAddress
      let myETHBalance
      let myCACATOBalance
      if (typeof window !== "undefined" && typeof window.ethereum !== "undefined") {
        try {
          await window.ethereum.request({ method: "eth_requestAccounts"})
          web3 = new Web3(window.ethereum)
          this.setState({buttonMessage: "Wallet is connected", navbarMessage: "Connection has been established."});
          accounts = await web3.eth.getAccounts();
          
        } catch(err) {
          this.setState({buttonMessage: "Connect Wallet", navbarMessage: err.message});
        }
      } else {
        console.log("Please install MetaMask");
        this.setState({buttonMessage: "Connect Wallet", navbarMessage: "Please install MetaMask, WalletConnect, or Coinbase wallet."});
      }
      myAddress = accounts[0];
      myETHBalance = await web3.eth.getBalance(accounts[0]);
      myETHBalance = web3.utils.fromWei(myETHBalance, 'ether');
      myCACATOBalance = await tokenContract.methods.balanceOf(accounts[0]).call();
      myCACATOBalance = web3.utils.fromWei(myCACATOBalance, 'ether'); 
      this.setState({myAddress, myETHBalance, myCACATOBalance});
    }
  
    async componentDidMount(){
      this.isConnected();
      let accounts = await web3Provider.eth.getAccounts();
      let myAddress = accounts[0];
      let myETHBalance = await web3Provider.eth.getBalance(accounts[0]);
      myETHBalance = web3Provider.utils.fromWei(myETHBalance, 'ether');
      let myCACATOBalance = await tokenContract.methods.balanceOf(accounts[0]).call();
      myCACATOBalance = web3Provider.utils.fromWei(myCACATOBalance, 'ether');
      this.setState({myAddress, myETHBalance, myCACATOBalance});
    }
  
    isConnected = async()=> {
       const accounts = await window.ethereum.request({method: 'eth_accounts'});       
       if (accounts.length) {
          console.log(`You're connected to: ${accounts[0]}`);
          //this.setState({buttonMessage: "Wallet is Connected", navbarMessage: "Connection has been established.", walletIsConnected: true});
       } else {
          console.log("Metamask is not connected.");
          //this.setState({buttonMessage: "Connect Wallet", navbarMessage: "Press button to connect your wallet.", walletIsConnected: false});
          
        }
    }

    handleEvent = (isConnected) => {
      this.setState({walletConnectConnected: isConnected});
      
    }

    render() {
    return (
    <div className="gpt3__navbar">
      <div className="textWidth">
          <div className="logoAndMenu">
            <div className="gpt3__navbar-links_logo">
              <a href="#home"><img  src={logo} /> </a>
            </div>
            <div className="gpt3__navbar-links_container">
                <this.Menu/>
            </div>
          </div>
        <div className="buttonNavbar">
          <WagmiConfig config={wagmiConfig}>
            <CustomButton handleEvent={this.handleEvent}/>
          </WagmiConfig>
          <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
          
        </div>
        <div className="gpt3__navbar-menu">
          {this.state.toggleMenu
            ? <RiCloseLine color="#fff" size={27} onClick={() => this.setState({toggleMenu: false})} />
            : <RiMenu3Line color="#fff" size={27} onClick={() => this.setState({toggleMenu: true}, )} />}
          {this.state.toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <this.Menu/>
            </div>
            <div className="gpt3__navbar-menu_container-links-sign">
              <button type="button">Connect Wallet</button>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  )
}
  };

export default Navbar;

/* <button type="button" onClick={this.connectWalletHandler}
            disabled={this.state.walletIsConnected}
                  className={this.state.walletIsConnected ? "buttonWalletIsConnected"  : "buttonWalletNOTConnected"}
                  >{this.state.buttonMessage}</button>
*/