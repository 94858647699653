export const reviewMessage1 = [
  'Jessie: This is what we need! Put your money just once and use it in all your games! It is like a dream!',
  'Alex: I am using it in several games and even was able to make some profit in Play-to-earn games!',
  'Sophia: With development of today\'s technology, Candy Cap Token is very useful for daily gaming.',
  'Evelyn: CACATO is saving my time and giving an extra security to money transfer. I can rely on this token!',
];

export const reviewMessage2 = [
  'Casey: This is what we need! Put your money just once and use it in all your games! It is like a dream!',
  'Jackie: I am using it in several games and even was able to make some profit in Play-to-earn games!',
  'Ariel: I am using it in several games and even was able to make some profit in Play-to-Earn games!',
  'Noel: I am using it in several games and even was able to make some profit in Play-to-earn games!',
];

export const reviewMessage3 = [
  'Emma: This is what we need! Put your money just once and use it in all your games! It is like a dream!',
  'Charlotte: I am using it in several games and even was able to make some profit in Play-to-earn games!',
  'Olivia: I am using it in several games and even was able to make some profit in Play-to-Earn games!',
  'Mia: I am using it in several games and even was able to make some profit in Play-to-earn games!',
];

export const reviewMessage4 = [
  'Charlie: This is what we need! Put your money just once and use it in all your games! It is like a dream!',
  'Kirby: I am using it in several games and even was able to make some profit in Play-to-earn games!',
  'Elliot: I am using it in several games and even was able to make some profit in Play-to-Earn games!',
  'Onyx: I am using it in several games and even was able to make some profit in Play-to-earn games!',
];

export const exploreWorlds = [
    {
      id: 'world-1',
      imgUrl: '/planet-01.jpg',
      title: 'Loose Sand',
    },
    {
      id: 'world-2',
      imgUrl: '/planet-02.jpg',
      title: 'Galaxy War',
    },
    {
      id: 'world-3',
      imgUrl: '/planet-03.jpg',
      title: 'Wide Lands',
    },
    {
      id: 'world-4',
      imgUrl: '/planet-04.jpg',
      title: 'Attack on Castle',
    },
    {
      id: 'world-5',
      imgUrl: '/planet-05.jpg',
      title: 'Ira The Witch',
    },
  ];
  
  export const startingFeatures = [
    'Find a world that suits you and you want to enter',
    'Enter the world by reading basmalah to be safe',
    'No need to beat around the bush, just stay on the gas and have fun',
  ];
  
  export const newFeatures = [
    {
      imgUrl: '/vrpano.svg',
      title: 'A new world',
      subtitle:
          'we have the latest update with new world for you to try never mind',
    },
    {
      imgUrl: '/headset.svg',
      title: 'More realistic',
      subtitle:
          'In the latest update, your eyes are narrow, making the world more realistic than ever',
    },
  ];
  
  export const insights = [
    {
      imgUrl: '/planet-06.png',
      title: 'The launch of the Metaverse makes Elon musk ketar-ketir',
      subtitle:
          'Magna etiam tempor orci eu lobortis elementum nibh tellus molestie. Diam maecenas sed enim ut sem viverra alique.',
    },
    {
      imgUrl: '/planet-07.png',
      title: '7 tips to easily master the madness of the Metaverse',
      subtitle:
          'Vitae congue eu consequat ac felis donec. Et magnis dis parturient montes nascetur ridiculus mus. Convallis tellus id interdum',
    },
    {
      imgUrl: '/planet-08.png',
      title: 'With one platform you can explore the whole world virtually',
      subtitle:
          'Quam quisque id diam vel quam elementum. Viverra nam libero justo laoreet sit amet cursus sit. Mauris in aliquam sem',
    },
  ];
  
  export const socials = [
    {
      name: 'twitter',
      url: '/twitter.svg',
    },
    {
      name: 'linkedin',
      url: '/linkedin.svg',
    },
    {
      name: 'instagram',
      url: '/instagram.svg',
    },
    {
      name: 'facebook',
      url: '/facebook.svg',
    },
  ];
  