import { motion } from 'framer-motion';
import './progressBar.css';
import { useRef } from "react";

import { staggerContainer, fadeIn } from '../../utils/motion';


function ProgressBar({value}) {
  const progressTextRef = useRef(null);
  const barStyles = (value) => ({
    hidden: {
      opacity: 1,
    },
    show: {
      opacity: 1,
      width: ["0%", `${value+9}%`, `${value-6}%`, `${value+3}%`, `${value-2}%`, `${value}%`],
      transition: {
        duration: 2,
        delay: 1,
        onUpdate : (width) => {
          const endIndex =  width.search('%')
          const decimalNumber = (width.substring(0, endIndex))
          const intNumber = Math.round(decimalNumber)
          progressTextRef.current.textContent = intNumber
      }}
    },
  });

    return(
    <div className="wrap">
        <div className="barWrap">
            <motion.div
              variants={barStyles(value)}
              initial="hidden"
              whileInView="show"
              className="bar"
            />
        </div>
        <div className="progressbar-text-container">
          <p style={{ marginRight: '.5rem' }}>Sold tokens</p>
          <p ref={progressTextRef}>0</p>
          <p>%</p>
        </div>

        



        <div className="tokenKeyMetrics">
        <motion.div
        className="parentContainer"
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
      >
            <div className="bulletPointsChildren">
            <motion.div
            variants={fadeIn('right', 'spring', 3.0, 0.75)}
            className={"bulletPoint"}
            ><p> • Network: Ethereum</p></motion.div>
            <motion.div
            variants={fadeIn('right', 'spring', 3.5, 0.75)}
            className={"bulletPoint"}
            ><p> • Token Type: ERC20</p></motion.div>    
            <motion.div
            variants={fadeIn('right', 'spring', 4.0, 0.75)}
            className={"bulletPoint"}
            ><p> • Circulating supply: 7,000,000,000 CACATO</p></motion.div>    
                
                
                
                
            </div>
        </motion.div>
        </div>
    </div>
    );
};
  
  export default ProgressBar;

