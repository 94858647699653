import React, { Component } from 'react';
import './reviews.css';
import { motion } from 'framer-motion';
import { staggerContainer, zoomIn } from '../../utils/motion';
import { TitleText } from '../../components';
import { reviewMessage1, reviewMessage2, reviewMessage3, reviewMessage4 } from '../../constants/index';

import map from '../../assets/map.png';
import { person0, person1, person2, person3, 
         person4, person5, person6, person7,
         person8, person9, person10, person11, 
         person12, person13, person14, person15
       } from '../../assets'
    
const images0 = [person0, person1, person2, person3];
const images1 = [person4, person5, person6, person7];
const images2 = [person8, person9, person10, person11];
const images3 = [person12, person13, person14, person15];

class Reviews extends Component {
  state = { 
    currentImageIndex1: Math.floor(Math.random() * images0.length),
    currentImageIndex2: Math.floor(Math.random() * images1.length),
    currentImageIndex3: Math.floor(Math.random() * images2.length),
    currentImageIndex4: Math.floor(Math.random() * images3.length),
  }
  
  render() {
    return(
    <div className="review section__margin" id="reviews">
      
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className="container">
          <div className="titleReviews">
            <TitleText title={<>Reviews</>}/>
          </div>
          <div className="images">
            <div className="reviewsBackgrowndImage">
              <img src={map} alt="map"/>
            </div>
            <div className="person1">
              <img src={images0[this.state.currentImageIndex1]} alt="person1"/>
              <motion.div
                variants={zoomIn(0.6, 1)}
                className="comment"
              >
                {reviewMessage1[this.state.currentImageIndex1]}
              </motion.div>
            </div>
            <div className="person2">
              <img src={images1[this.state.currentImageIndex2]} alt="person2"/>
              <motion.div
                variants={zoomIn(0.2, 0.6)}
                className="comment"
              >
                {reviewMessage2[this.state.currentImageIndex2]}
              </motion.div>
            </div>
            <div className="person3">
              <img src={images2[this.state.currentImageIndex3]} alt="person3"/>
              <motion.div
                variants={zoomIn(0.4, 0.8)}
                className="comment"
              >
                {reviewMessage3[this.state.currentImageIndex3]}
              </motion.div>
            </div>
            <div className="person4">
              <img src={images3[this.state.currentImageIndex4]} alt="person4"/>
              <motion.div
                variants={zoomIn(0.8, 1)}
                className="comment"
              >
                {reviewMessage4[this.state.currentImageIndex4]} 
              </motion.div>
            </div>
          </div>
      </motion.div>
    </div>
    )
  }
}

export default Reviews;
