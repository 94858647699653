import React from 'react';
import blockchain from '../../assets/blockchain.png';
import './aboutCACATO.css';
import { motion } from 'framer-motion';
import { staggerContainer, zoomIn } from '../../utils/motion';
import { TitleText } from '../../components';

const AboutCACATO = () => {
  return (
    <div className="gpt3__aboutCACATO section__margin " id="aboutCACATO">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className="container">
            <div className="titleReviews">
            <TitleText title={<>About CACATO</>}/>
            </div>
            <div className="gpt3__aboutCACATO-content">
            <p>This is a lightswitch moment for many - cross-game token is a reality! 
                Powered by blockchain, CACATO provides a way to decentralize all in-game payments. 
                This is a new convenient way to buy in-game assets like loot boxes, skins, and other items in all the favorite titles. 
                Play-To-Earn games are using CACATO to provide a deserved rewards. 
                Getting into a new game - your capital is moving with you!
            </p>
            </div>
          <div className="gpt3__aboutCACATO-image">
            <motion.div
              variants={zoomIn(0.0, 1)}
            >
              <img src={blockchain} alt="blockchain"/>
            </motion.div>
          </div>
          <div className="gpt3__aboutCACATO-address">
            <h1 className="title__text">Token address: </h1>
            <a href="https://goerli.etherscan.io/address/0x0d71919e1AF247AD1F53E059C4C3d6505Cc55644" target="_blank">0x0d71919e1AF247AD1F53E059C4C3d6505Cc55644
            </a>
          </div>


       </motion.div>
    </div>
    
    
  )
}

export default AboutCACATO


/*

          


          */