import Web3 from 'web3';

let web3Provider;

if (typeof window !== 'undefined' && typeof window.web3 !== 'undefined') {

  web3Provider = new Web3(window.web3.currentProvider);
} /*else {
  const provider = new Web3.providers.HttpProvider( 
    "https://goerli.infura.io/v3/4e342d80eedd4ee88f4280ba529cc140"
);
}*/



export default web3Provider;

