import React from 'react';
import './footer.css';
import { socials } from '../../constants';
import logo from '../../assets/logoWhite.svg';

const Footer = () => {
  return (
    <div className="footerMain section__margin">
        <div className="line"/>
        <div className="underline">
          <div className="footerImageLogo">
           <a href="#home"><img className="footerLogo" src={logo} /> </a>
          </div>
          <p className="textRights">
            Copyright © 2023 CACATO. All rights reserved.
          </p>

          <div className="footerIcons">
            {socials.map((social) => (
              <img
                key={social.name}
                src={social.url}
                alt={social.name}
                className="icons"
              />
            ))}
          </div>
        </div>
      </div>
    
  )
}

export default Footer