import { useWeb3Modal } from "@web3modal/react";
import { useState } from "react";
import { useEffect } from "react";
import { useAccount, useDisconnect } from "wagmi";
import './customButton.css';
import { Web3Modal, Web3Button, useWeb3ModalTheme   } from '@web3modal/react'

export default function CustomButton({handleEvent}) {
  
  const { theme, setTheme } = useWeb3ModalTheme()
  const [loading, setLoading] = useState(false);
  const { open } = useWeb3Modal();
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const label = isConnected ? "Connected" : "Connect Wallet";
  
  async function onOpen() {
    setLoading(true);
    await open();
    setLoading(false);
    
  }

  useEffect(() => {
    handleEvent(isConnected);
  }, [isConnected, handleEvent]);

  function onClick() {
    setTheme({
      themeMode: 'light',
      themeVariables: {
        '--w3m-font-family': 'Roboto, sans-serif',
        '--w3m-accent-color': 'green',
        '--w3m-background-color': 'green',
      }
    })
    if (isConnected) {
      disconnect();
    } else {
      onOpen();
    }
  }
  return (
    <div>
      <button className="customButton" onClick={onClick} disabled={loading}>
        {loading ? "Loading..." : label}
      </button>
    </div>
  );
}