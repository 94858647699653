import { motion } from 'framer-motion';
import './exploreCards.css';
import styles from '../styles/globals.css';
import { fadeIn } from '../utils/motion.js';

const ExploreCard = ({ id, imgUrl, title, index, active, handleClick }) => (
  
  
  <motion.div
  variants={fadeIn('right', 'spring', index * 0.5, 0.75)}
  className={active===id ? "cardImages2" : "cardImages"}
  
  onClick={() => handleClick(id)}
  >
    <img
      src={imgUrl}
      alt="planet-04"
    />
    
    {active !== id ? (
      <h3 className="titleStyle1">
        {title}
      </h3>
    ) : (
      
        
        
        <h2 className="titleStyle2">
          {title}
        </h2>
      
    )}


  </motion.div>
);

export default ExploreCard;
