export const person0 = require('./person0.png');
export const person1 = require('./person1.png');
export const person2 = require('./person2.png');
export const person3 = require('./person3.png');
export const person4 = require('./person4.png');
export const person5 = require('./person5.png');
export const person6 = require('./person6.png');
export const person7 = require('./person7.png');
export const person8 = require('./person8.png');
export const person9 = require('./person9.png');
export const person10 = require('./person10.png');
export const person11 = require('./person11.png');
export const person12 = require('./person12.png');
export const person13 = require('./person13.png');
export const person14 = require('./person14.png');
export const person15 = require('./person15.png');